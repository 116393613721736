import React from 'react'
import Header from 'components/Header'

export default function NotFound() {
  return (
    <>
      <div className="min-h-screen h-full bg-black">
        <Header withUser={false} />
        <p className="my-6 lg:text-5xl md:text-3xl text-3xl text-center font-bold text-white">
          Page not found 404
        </p>
      </div>
    </>
  )
}
